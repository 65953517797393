import { NextPage } from "next";
import ProductCarousel from "./product-carousel";
import ContactForm from "./contact-form";
import Image from "next/image";
import { IProduct } from "../../types/product";
import { useState } from "react";
import { MainContent } from ".";
import PageContent from "../contact/page-content";
import { useTranslation } from 'next-i18next';

interface IProductCarousel {
  products: IProduct[];
}

const VideoHome: NextPage<IProductCarousel> = ({ products }) => {
  const [play, setPlay] = useState(0);
  const { t } = useTranslation();

  return (
    <section className="self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-20 box-border max-w-full text-left text-29xl text-text-title font-headline mq850:pb-[34px] mq850:box-border mq1225:pb-[52px] mq1225:box-border mq450:pb-[22px] mq450:box-border">
      <div className="w-[1440px] flex flex-col items-start justify-start gap-[80px] max-w-full mq850:gap-[40px] mq450:gap-[20px]">
        <div className="self-stretch flex flex-col items-start justify-start gap-[32px] max-w-full mq850:gap-[16px]">
          <h1 className="m-0 relative text-inherit font-medium font-inherit mq850:text-19xl mq450:text-10xl">
            {t('home.video.title')}
          </h1>
          <div className="self-stretch flex flex-row items-start justify-start gap-[32px] max-w-full text-base mq850:gap-[16px] mq1225:flex-wrap">
            <div className="flex-1 flex flex-row items-start justify-start px-0 pb-0 box-border min-w-[458px] max-w-full mq850:min-w-full mq1225:flex-1">
              <iframe
                onClick={() => setPlay(play == 0 ? 1 : 0)}
                className="h-[339.2px] flex-1 rounded-xl flex flex-row items-start justify-center box-border  bg-cover bg-no-repeat bg-[top] max-w-full"
                src={`https://www.youtube.com/embed/yVYC6-dcWb8?si=hzEZqIACxSoUM26H&autoplay=${play}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              />
            </div>
            <div className="flex-[0.9148] min-h-[339.2px] rounded-xl bg-whitesmoke-100 flex flex-col items-start justify-start py-[60px] pr-5 pl-10 box-border gap-[24px] min-w-[458px] max-w-full mq850:min-w-full mq1225:flex-1">
              <div className="relative font-medium inline-block max-w-full">
                {t('home.video.factory_title')}
              </div>
              <div className="w-[624px] relative text-sm text-text-content-1 inline-block max-w-full">
                <p className="m-0">
                  <span className="font-light">{t('home.video.intro_text')}</span>
                </p>
                <br />
                <p className="m-0">
                  <span className="font-light">{t('home.video.call_to_action')}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-between gap-[20px] mq850:flex-wrap">
          <h1 className="m-0 relative text-inherit font-medium font-inherit mq850:text-19xl mq450:text-10xl">
            {t('nav.products')}
          </h1>
        </div>
        <ProductCarousel products={products} />
        <PageContent />
      </div>
    </section>
  );
};

export default VideoHome;
