import type { NextPage } from "next";
import { memo } from "react";
import { useTranslation } from 'next-i18next';

export type AchievementType = {
  className?: string;
};

const Achievement: NextPage<AchievementType> = ({ className = "" }) => {
  const { t } = useTranslation();

  return (
    <section
      className={`self-stretch flex flex-row items-start justify-start pt-0 px-0 box-border max-w-full text-left text-29xl text-white font-headline mq850:pb-[52px] mq850:box-border ${className}`}
    >
      <div className="flex-1 flex flex-row items-center justify-center py-[88px] px-5 box-border relative max-w-full mq850:pt-[57px] mq850:pb-[57px] mq850:box-border mq1225:box-border  mq450:box-border">
        <img
          className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
          loading="lazy"
          alt=""
          src="/image-13@2x.png"
        />
        <div className="w-[1440px] flex flex-col items-start justify-start gap-[80px] max-w-full mq850:gap-[40px] mq450:gap-[20px]">
          <div className="self-stretch flex flex-col items-start justify-start">
            <h1 className="m-0 self-stretch relative text-inherit font-medium font-inherit mq850:text-19xl mq450:text-10xl">
              {t('home.achievement.trusted_partner')}
            </h1>
          </div>
          <div className="flex flex-col items-start justify-center gap-[20px] text-21xl">
            <div className="flex flex-col items-start justify-start">
              <div className="relative inline-block min-w-[117px] mq850:text-13xl mq450:text-5xl">
                10000+
              </div>
              <h2 className="m-0 relative text-5xl font-light font-inherit mq450:text-lgi">
                {t('home.achievement.customers')}
              </h2>
            </div>
            <div className="flex flex-col items-start justify-start">
              <div className="relative inline-block min-w-[117px] mq850:text-13xl mq450:text-5xl">
                1000+
              </div>
              <h2 className="m-0 relative text-5xl font-light font-inherit mq450:text-lgi">
                {t('home.achievement.ton')}
              </h2>
            </div>
            <div className="flex flex-col items-start justify-start">
              <div className="relative inline-block min-w-[93px] mq850:text-13xl mq450:text-5xl">
                100+
              </div>
              <h2 className="m-0 relative text-5xl font-light font-inherit mq450:text-lgi">
                {t('home.achievement.export_countries')}
              </h2>
            </div>
            <div className="flex flex-col items-start justify-start">
              <div className="relative inline-block min-w-[68px] mq850:text-13xl mq450:text-5xl">
                10+
              </div>
              <h2 className="m-0 relative text-5xl font-light font-inherit mq450:text-lgi">
                {t('home.achievement.years_experience')}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default memo(Achievement);
