import type { NextPage } from "next";
import { Button } from "@mui/material";
import NewsItemContainer from "./news-item-container";
import { memo } from "react";
import { INewsData } from "../../types/news";
import NewsSlider from "./news-slider";
import PressSlider from "./press-slider";

export type NewsHomeType = {
  className?: string;
  newsArticles: INewsData[];
};
const NewsHome: NextPage<NewsHomeType> = ({
  className = "",
  newsArticles = [],
}) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-20 box-border max-w-full text-left text-29xl text-text-title font-headline mq850:pb-[52px] mq850:box-border ${className}`}
    >
      <div className="w-[1440px] flex flex-col items-start justify-start gap-[80px] max-w-full mq850:gap-[40px] mq450:gap-[20px]">
        <div className="self-stretch flex flex-col items-start justify-start gap-[32px] max-w-full mq850:gap-[16px]">
          <div className="self-stretch flex flex-row items-center justify-between gap-[20px] mq450:flex-wrap">
            <h1 className="m-0 relative text-inherit font-medium font-inherit mq850:text-19xl mq450:text-10xl">
              Tin tức
            </h1>
            <Button
              className="h-10 w-[173px]"
              href="/tin-tuc"
              endIcon={
                <img
                  width="24px"
                  height="24px"
                  src="/arrow-right-3.svg"
                  alt=""
                />
              }
              disableElevation
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#fff",
                fontSize: "16",
                background: "#d8920b",
                borderRadius: "32px",
                "&:hover": { background: "#d8920b" },
                width: 173,
                height: 40,
              }}
            >
              Xem thêm
            </Button>
          </div>
          <NewsSlider newsArticles={newsArticles} />

        </div>
        <PressSlider/>
      </div>
    </section>
  );
};

export default memo(NewsHome);
