import React, { memo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const PressSlider = () => {

    return (
        <div className="w-full max-w-[1440px] mx-auto relative">
            <h1 className="m-0 mb-[32px] relative text-inherit font-medium font-inherit inline-block max-w-full mq850:text-19xl mq450:text-10xl">
                Báo chí nói về chúng tôi
            </h1>

            <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={24}
                slidesPerView={'auto'}
                pagination={{ clickable: true }}
                style={{ width: '100%' }}
            >
                <SwiperSlide style={{ width: '202px' }}>
                    <div className="h-[125px] w-[202px] relative flex items-center justify-center">
                        <img
                            className="w-[202px] h-[82.55px] object-fit rounded-[16px]"
                            loading="lazy"
                            alt=""
                            src="/rectangle-26@2x.png"
                        />
                    </div>
                </SwiperSlide>
                <SwiperSlide style={{ width: '303px' }}>
                    <img
                        className="w-[303px] h-[125px] object-fit rounded-[16px]"
                        loading="lazy"
                        alt=""
                        src="/rectangle-22@2x.png"
                    />
                </SwiperSlide>
                <SwiperSlide style={{ width: '202px' }}>
                    <img
                        className="w-[202px] h-[125px] object-fit rounded-[16px]"
                        loading="lazy"
                        alt=""
                        src="/rectangle-24@2x.png"
                    />
                </SwiperSlide>
                <SwiperSlide style={{ width: '202px' }}>
                    <img
                        className="w-[202px] h-[125px] object-fit rounded-[16px]"
                        loading="lazy"
                        alt=""
                        src="/rectangle-23@2x.png"
                    />
                </SwiperSlide>
                <SwiperSlide style={{ width: '202px' }}>
                    <div className="h-[125px] w-[202px] relative flex items-center justify-center">
                        <img
                            className="w-[202px] h-[106px] object-fit rounded-[16px]"
                            loading="lazy"
                            alt=""
                            src="/rectangle-25@2x.png"
                        />
                    </div>
                </SwiperSlide>
            </Swiper>


        </div>
    );
};

export default memo(PressSlider);