import type { NextPage } from "next";
import { Box } from "@mui/material";
import ProductCards from "../products/product-cards";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { IProduct } from "../../types/product";
import { getImageUrl } from "../../utils/helper";
import { useTranslation } from "next-i18next";

export type ProductCarouselType = {
  className?: string;
  products: IProduct[];
};

const ProductCarousel: NextPage<ProductCarouselType> = ({
  className = "",
  products,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`self-stretch flex flex-col items-center justify-center gap-[32px] max-w-full text-left text-29xl text-text-title font-headline mq850:gap-[16px] ${className}`}
    >
      <Box className="carousel-container w-full relative">
        {products?.length ? (
          <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={20}
            loopAddBlankSlides={false}
            slidesPerView={'auto'}
            pagination={{
              clickable: true,
              el: '.custom-pagination',
              type: 'bullets',
            }}
            maxBackfaceHiddenSlides={4}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            className="product-swiper"
            breakpoints={{
              0: { 
                slidesPerView: 1.2, 
                spaceBetween: 10,
              },
              640: { 
                slidesPerView: 2.5, 
                spaceBetween: 20,
              },
              1024: { 
                slidesPerView: 4, 
                spaceBetween: 30,
              }
            }}
            allowTouchMove={true}
            simulateTouch={true}
          >
            {products.map((product) => (
              <SwiperSlide key={product.id + "displayedProducts"} className="!w-[280px] !flex-shrink-0">
                <ProductCards
                  productImages={getImageUrl(product.image)}
                  propHeight="341px"
                  propFlex="1"
                  propMinWidth="280px"
                  propGap="12px"
                  product={product}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <div className="m-0 relative text-inherit font-medium font-inherit text-10xl ml-20 mt-2">
            {t("Không tìm thấy sản phẩm")}
          </div>
        )}
        <div className="custom-pagination flex justify-center mt-4"></div>
      </Box>
      <style jsx global>{`
        .custom-pagination .swiper-pagination-bullet {
          width: 10px;
          height: 10px;
          background-color: #888;
          opacity: 0.5;
          border-radius: 50%;
          margin: 0 5px;
        }
        .custom-pagination .swiper-pagination-bullet-active {
          background-color: #000;
          opacity: 1;
        }
      `}</style>
    </div>
  );
};

export default ProductCarousel;
