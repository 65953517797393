import type { NextPage } from "next";
import { memo } from "react";
import { INewsData } from "../../types/news";
import { getImageUrl } from "../../utils/helper";
import { fDate } from "../../utils/format-time";
import Link from "next/link";

export type NewsItemContainerType = {
  className?: string;
  item?: INewsData;
};

const NewsItemContainer: NextPage<NewsItemContainerType> = ({
  className = "",
  item,
}) => {
  return (
    <Link href={`/tin-tuc/${item?.slug}`} style={{ textDecoration: "none" }}>
      <div
        className={`flex flex-col items-start justify-start gap-[12px] max-w-full text-left text-xs text-orangered-200 font-headline ${className}`}
      >
        <div className="relative w-[336px] h-[205px] overflow-hidden">
          <img
            className="w-full h-full object-cover rounded-[16px]" // Áp dụng kích thước và bo tròn
            loading="lazy"
            alt=""
            src={getImageUrl(item?.image || "")}
          />
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
          <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap">
            <div className="relative font-light inline-block min-w-[68px]">
              {fDate(item?.dateModified || "")}
            </div>
            <div className="flex flex-row items-center justify-start gap-[4px] text-status-active">
              <div className="relative font-light inline-block min-w-[58px]">
                Xem thêm
              </div>
              <img
                className="h-4 w-4 relative overflow-hidden shrink-0 min-h-[16px]"
                alt=""
                src="/arrow-right-4.svg"
              />
            </div>
          </div>
          <div className="self-stretch relative text-base font-medium text-text-title">
            {item?.title}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default memo(NewsItemContainer);
