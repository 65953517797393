import type { NextPage } from "next";
import { Button } from "@mui/material";
import { memo, useEffect, useState } from "react";
import Image from "next/image";
import { IBanner } from "../../types/home";
import { getImageUrl } from "../../utils/helper";
import Link from "next/link";
import { useTranslation } from 'next-i18next';

export type MainContentType = {
  className?: string;
  banners: IBanner[];
};
let currentIndex = 0;
const MainContent: NextPage<MainContentType> = ({
  className = "",
  banners,
}) => {
  const { t } = useTranslation()

  const [banner, setActiveBanner] = useState<IBanner[]>([]);
  useEffect(() => {
    setActiveBanner(banners);
  }, [banners]);

  function next() {
    currentIndex = (currentIndex + 1) % banner.length;
    let newArr = [
      banner[currentIndex],
      ...banner.filter((_, index) => index !== currentIndex),
    ];
    setActiveBanner(newArr);
  }
  function prev() {
    currentIndex = (currentIndex - 1 + banner.length) % banner.length;
    let newArr = [
      banner[currentIndex],
      ...banner.filter((_, index) => index !== currentIndex),
    ];
    setActiveBanner(newArr);
  }
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-20 box-border max-w-full text-left text-13xl text-white font-headline mq850:pb-[52px] mq850:box-border mq450:pb-[34px] mq450:box-border ${className}`}
    >
      <div className="flex-1 bg-primary flex flex-row items-start justify-start py-[60px] pr-0 pl-[135px] box-border gap-[40px] max-w-full mq850:gap-[32px] mq850:pt-[39px] mq850:pb-[39px] mq850:box-border mq850:flex-wrap mq850:pl-5 mq850:pr-5 mq850:box-border">
        <div
          style={{
            minWidth: "45%",
          }}
          className="flex flex-col items-start justify-between py-0 pr-[120px] mq1525:pr-0 pl-0 box-border min-w-[491px] min-h-[580px] max-w-full text-29xl mq850:pr-[90px] mq850:box-border mq850:gap-[20px] mq450:min-w-full mq850:min-h-[auto] mq450:pr-0 mq450:box-border"
        >
          <div className="self-stretch flex flex-col items-start justify-start gap-[60px] max-w-full mq850:gap-[30px]">
            <div className="flex flex-col items-start justify-start gap-[20px] max-w-full">
              <h1 className="m-0 relative text-inherit font-medium font-inherit mq850:text-19xl mq450:text-10xl">
                {t('home.factory_title')}
              </h1>
              <div className="w-[350px] flex flex-col items-start justify-start gap-[12px] text-5xl">
                <h2 className="m-0 relative text-inherit font-light font-inherit inline-block mq450:text-lgi">
                  <ul className="m-0 font-inherit text-inherit pl-8 list-disc">
                    <li>{t('home.products.poly_mailers')}</li>
                  </ul>
                </h2>
                <div className="relative font-light inline-block mq450:text-lgi">
                  <ul className="m-0 font-inherit text-inherit pl-8 list-disc">
                    <li>{t('home.products.poly_mailers_handle')}</li>
                  </ul>
                </div>
                <h2 className="m-0 self-stretch relative text-inherit font-light font-inherit mq450:text-lgi">
                  <ul className="m-0 font-inherit text-inherit pl-8 list-disc">
                    <li>{t('home.products.poly_mailers_sealing')}</li>
                  </ul>
                </h2>
                <h2 className="m-0 relative text-inherit font-light font-inherit inline-block mq450:text-lgi">
                  <ul className="m-0 font-inherit text-inherit pl-8 list-disc">
                    <li>{t('home.products.bio_mailers')}</li>
                  </ul>
                </h2>
                <h2 className="m-0 relative text-inherit font-light font-inherit inline-block mq450:text-lgi">
                  <ul className="m-0 font-inherit text-inherit pl-8 list-disc">
                    <li>{t('home.products.zip_bag')}</li>
                  </ul>
                </h2>
                <h2 className="m-0 relative text-inherit font-light font-inherit inline-block mq450:text-lgi">
                  <ul className="m-0 font-inherit text-inherit pl-8 list-disc">
                    <li>{t('home.products.thermal')}</li>
                  </ul>
                </h2>
                <h2 className="m-0 relative text-inherit font-light font-inherit inline-block mq450:text-lgi">
                  <ul className="m-0 font-inherit text-inherit pl-8 list-disc">
                    <li>{t('home.products.labels')}</li>
                  </ul>
                </h2>
              </div>
            </div>
            <div className="flex flex-row items-start justify-start gap-[60px] max-w-full mq450:justify-center  mq450:gap-[30px]">
              <Button
                className="h-[50px] w-[610px] min-w-[142px]"
                disableElevation
                variant="outlined"
                href="/products"
                sx={{
                  textTransform: "none",
                  color: "#d8920b",
                  fontSize: { xs: "16", sm: "24" },
                  fontWeight: "500",
                  borderColor: "#d8920b",
                  borderRadius: "32px",
                  "&:hover": { borderColor: "#d8920b" },
                  height: 50,
                  width: 610,
                }}
              >
                {t('home.view_more')}
              </Button>
              <Button
                className="h-[50px] w-[610px] min-w-[142px]"
                disableElevation
                variant="contained"
                href="/products"
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  fontSize: "24",
                  fontWeight: "500",
                  background: "#d8920b",
                  borderRadius: "32px",
                  "&:hover": { background: "#d8920b" },
                  width: 610,
                  height: 50,
                }}
              >
                {t('home.order_now')}
              </Button>
            </div>
          </div>
          <div className="flex flex-row items-start justify-start gap-[60px] max-w-full mq450:justify-center  mq450:gap-[30px]">
            <div className="flex flex-col items-start justify-start">
              <div className="relative font-light inline-block min-w-[84px] mq850:text-19xl mq450:text-10xl">
                1000+
              </div>
              <h2 className="m-0 relative text-5xl font-light font-inherit mq450:text-base">
                {t('home.stats.partners')}
              </h2>
            </div>
            <div className="flex flex-col items-start justify-start">
              <div className="relative font-light inline-block min-w-[80px] mq850:text-19xl mq450:text-10xl">
                10+
              </div>
              <h2 className="m-0 relative text-5xl font-light font-inherit mq450:text-base">
                {t('home.stats.experience')}
              </h2>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-[21px] max-w-full">
          <div className="flex flex-row items-start justify-start gap-[40px]">
            {banner?.length &&
              banner.map((item, id) => (
                <div key={`arr${id}`}>
                  <Link
                    href={item.link?.length ? item.link : "#"}
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      key={id}
                      className={`flex-row items-end justify-start p-8 box-border relative min-h-[519px] min-w-[550px] mq450:min-w-[350px] mq850:min-h-[250px] ${0 === id ? "flex" : "hidden"
                        }`}
                    >
                      <Image
                          className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-xl max-w-full overflow-hidden max-h-full object-cover"
                          fill
                          alt={`IMAGE-${item}`}
                          src={item.imageUrl}
                          objectFit={"fill"}
                          sizes="(min-width: 550px) 550px"
                          priority
                        />

                    </div>
                  </Link>
                  <Link
                    href={item.link?.length ? item.link : "#"}
                    style={{ textDecoration: "none" }}
                    className="mq850:hidden"
                  >
                    <div
                      className={`h-[519px] w-[180px] flex items-center justify-center relative box-border mq450:pl-[35px] mq450:pr-[35px] ${0 !== id ? "flex" : "hidden"
                        }`}
                      key={`${id}next`}
                    >
                      <Image
                        className="h-full w-full absolute top-0 right-0 bottom-0 left-0 rounded-xl max-w-full overflow-hidden max-h-full object-cover"
                        alt="Hoạt động nhà máy"
                        fill
                        src={getImageUrl(item.image)}
                        loading="lazy"
                        sizes="(max-width: 180px) 180px"
                      />
                      <h1 className="text-inherit font-medium inline-block [transform:_rotate(-90deg)] shrink-0 z-[1] mq850:text-7xl mq450:text-lgi">
                        {item.description}
                      </h1>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
          <div className="w-[180px] flex flex-row items-start justify-start py-0 pl-0 box-border gap-[8px]">
            <Button
              className="h-10 flex-1"
              onClick={prev}
              endIcon={
                <img width="24px" height="24px" src="/arrow-left.svg" alt="" />
              }
              disableElevation
              variant="contained"
              sx={{
                background: "#9e9e9e",
                borderRadius: "32px",
                "&:hover": { background: "#9e9e9e" },
                height: 40,
              }}
            />
            <Button
              className="h-10 flex-1"
              endIcon={
                <img width="24px" height="24px" src="/arrow-right.svg" alt="" />
              }
              onClick={next}
              variant="contained"
              sx={{
                background: "#d8920b",
                borderRadius: "32px",
                "&:hover": { background: "#d8920b" },
                height: 40,
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default memo(MainContent);
