import React, { memo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperType } from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import NewsItemContainer from './news-item-container';
import { INewsData } from '../../types/news';
import { NextPage } from 'next';

interface NewsSliderProps {
  newsArticles: INewsData[];
}

const NewsSlider: NextPage<NewsSliderProps> = ({ newsArticles }) => {
  return (
    <div className="w-full max-w-[1440px] mx-auto relative">
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={24}
        slidesPerView={'auto'}
        pagination={{ clickable: true }}
        style={{ width: '100%' }}
      >
        {newsArticles?.length ? (
          newsArticles.map((item: INewsData) => (
            <SwiperSlide key={`newhome${item.slug}`} style={{width:"336px"}}>
              <NewsItemContainer item={item} />
            </SwiperSlide>
          ))
        ) : (
          <div></div>
        )}
      </Swiper>
    </div>
  );
};

export default memo(NewsSlider);