import { Button } from "@mui/material";
import type { NextPage } from "next";
import Link from "next/link";
import { useMemo, type CSSProperties } from "react";
import { IProduct } from "../../types/product";
import { vndong } from "../../utils/format-number";
import { linkProduct } from "../../utils/helper";

export type ProductCardsType = {
  className?: string;
  productImages?: string;

  /** Style props */
  propHeight?: CSSProperties["height"];
  propFlex?: CSSProperties["flex"];
  propMinWidth?: CSSProperties["minWidth"];
  propGap?: CSSProperties["gap"];
  product?: IProduct;
};

const ProductCards: NextPage<ProductCardsType> = ({
  className = "",
  productImages,
  propHeight,
  propFlex,
  propMinWidth,
  propGap,
  product,
}) => {
  const productCardsStyle: CSSProperties = useMemo(() => {
    return {
      height: propHeight,
      flex: propFlex,
      minWidth: propMinWidth,
      gap: propGap,
      width: "100%", // Ensure full width of the container
    };
  }, [propHeight, propFlex, propMinWidth, propGap]);

  return (
    <Button
      href={linkProduct(product?.slug, product?.id)}
      style={{ 
        textDecoration: "none", 
        width: "100%", 
        display: "block" 
      }}
    >
      <div
        className={`h-[341px] flex flex-col items-start justify-start gap-[12px] max-w-full text-left text-xl text-text-title font-headline w-full ${className}`}
        style={productCardsStyle}
      >
        <img
          className="self-stretch flex-1 relative rounded-lg max-w-full overflow-hidden max-h-full object-cover"
          alt=""
          src={productImages}
        />
        <div className="self-stretch rounded-xl flex flex-col items-center justify-start py-1 px-5 gap-[4px] w-full">
          <h3 className="m-0 relative text-inherit font-medium font-inherit inline-block min-w-[122px] mq450:text-base text-center text-xl w-full">
            {product?.title}
          </h3>
        </div>
      </div>
    </Button>
  );
};

export default ProductCards;
