import { ReactNode } from "react";
import Header from "../components/common/header";
import Footer from "../components/common/footer";

interface LayoutProps {
  children: ReactNode;
  linkText: string;
  keyRender: string;
  cate?: string;
}

const Layout = ({ children, linkText, keyRender, cate }: LayoutProps) => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal] text-left text-xl text-white font-headline">
      <Header linkText={linkText} keyRender={keyRender} cate={cate}/>
      {children}
      <Footer
        image6="/image-6@2x.png"
        image7="/image-7@2x.png"
        vecteezySnapchatLogoPngSn="/pngtreewhatsapp-icon-whatsapp-logo-whatsapp-3584845-1@2x.png"
        vecteezyViberLogoPngViber="/vecteezy_skype.png"
        image8="/image-8@2x.png"
        image9="/image-8@2x.png"
        image10="/image-10@2x.png"
        fanpagePlaceholder="/rectangle-6@2x.png"
      />
    </div>
  );
};

export default Layout;
